/* eslint-disable react/no-unescaped-entities */
import React from "react";
import "./OtherWaysToParticipate.scss";
import LinkButton from "../../components/LinkButton/LinkButton";
import pointsIcon from "../../assets/images/database-black.svg";
import Card from "../Card/Card";
import Tooltip from "../Tooltip/Tooltip";
import { EButtonStyle } from "../../utils/buttonUtils";
//import { cleanup } from "@testing-library/react";

//const PRIVACY_SHAREPOINT_URL = `https://twodegrees1.sharepoint.com/teams/PrivacySlalom`;

const buttonLabels = {
  CLAIM_POINTS: "Claim Points",
  ONE_PASSWORD: "Join 1Password",
  CHECK_IT_OUT: "Check it out",
  LEARN_MORE: "Learn More"
};

const CLAIM_POINTS_PAGE = "/events";
//const MAIL_TO_HACKTOBER = "mailto:hacktober@slalom.com";
const MAIL_TO_SECURITY = "mailto:security@slalom.com";
//const MAIL_TO_PRIVACY = "mailto:privacy@slalom.com";

interface CardProps {
  title: string;
  points: number;
  description: React.ReactElement;
  clue: React.ReactElement;
  includeClue: boolean;
  buttonValue: string;
  buttonHref: string;
}

const OtherWaysToParticipateCard: React.FC<CardProps> = ({
  title,
  points,
  description,
  clue,
  includeClue,
  buttonValue,
  buttonHref
}): React.ReactElement => (
  <Card>
    <div>
      <div className="tile-title">
        {title}
        {includeClue && (
          <div className="tile-tooltip">
            <Tooltip>{clue}</Tooltip>
          </div>
        )}
      </div>
      <div className="tile-points">
        <img src={pointsIcon} className="points-icon" alt="points icon" />
        <div className="points-value">{points} Points</div>
      </div>
      {description}
    </div>
    <div></div>
    <div className="tile-button-container">
      <LinkButton
        text={buttonValue}
        style={EButtonStyle.PRIMARY}
        href={buttonHref}
        newTab={true}
      />
    </div>
  </Card>
);

const AnitaBDescription = () => (
  <p className="tile-description">
    Discover the clue word by exploring{" "}
    <a href={"https://anitab.org/"} target="_blank" rel="noopener noreferrer">
      AnitaB
    </a>{" "}
    and using the clue found at the top right of the card! Submit the clue in
    the "Claim Points" section on the Events page to earn points.
    <br />
    <br />
    Need help? Reach out to women@slalom.com.
  </p>
);

const AnitaBClue = () => (
  <p className="tile-description">
    Clue: Anita B<span>&apos;</span>s mission is to envision a future where the
    people who imagine and build technology _______ the people and societies for
    whom they build it.
  </p>
);

const signUpForOnePasswordDescription = () => (
  <p className="tile-description">
    Request an invite from{" "}
    <a href={MAIL_TO_SECURITY} target="_blank" rel="noopener noreferrer">
      security@slalom.com
    </a>{" "}
    and mention it is for Women<span>&apos;</span>s History Month to earn
    points! If you&apos;re already signed up for 1Password, your points have
    automatically been added.
    <br></br>
    <br></br>
    <br></br>
  </p>
);

const signUpForOnePasswordClue = () => <p className="tile-description"> </p>;

const EveryGirlShinesDescription = () => (
  <p className="tile-description">
    Discover the clue word by exploring{" "}
    <a
      href={"https://everygirlshines.org/"}
      target="_blank"
      rel="noopener noreferrer"
    >
      Every Girl Shines
    </a>{" "}
    and using the clue found at the top right of the card! Submit the clue in
    the "Claim Points" section on the Events page to earn points.
    <br />
    <br />
    Need help? Reach out to women@slalom.com.
  </p>
);

const EveryGirlShinesClue = () => (
  <p className="tile-description">
    Clue: On the Stories page, what mentee said the following: “My mentor
    empowers me to see a possible future and believe I can do it."
  </p>
);

const JessieCentreDescription = () => (
  <p className="tile-description">
    Discover the clue word by exploring{" "}
    <a
      href={"https://jessiescentre.org/"}
      target="_blank"
      rel="noopener noreferrer"
    >
      Jessie&apos;s Centre
    </a>{" "}
    and using the clue found at the top right of the card! Submit the clue in
    the "Claim Points" section on the Events page to earn points.
    <br />
    <br />
    Need help? Reach out to women@slalom.com.
  </p>
);

const JessieCentreClue = () => (
  <p className="tile-description">
    Clue: There are many ways Jessie<span>&apos;</span>s can help with the
    practical life details that can be challenging for young pregnant women and
    young moms. For example, the Swap Shop offers ____ donated items for those
    in need.
  </p>
);

const ProjectHopeDescription = () => (
  <p className="tile-description">
    Discover the clue word by exploring{" "}
    <a
      href={"https://www.projecthope.org/"}
      target="_blank"
      rel="noopener noreferrer"
    >
      Project HOPE
    </a>{" "}
    and using the clue found at the top right of the card! Submit the clue in
    the "Claim Points" section on the Events page to earn points.
    <br />
    <br />
    Need help? Reach out to women@slalom.com.
  </p>
);

const ProjectHopeClue = () => (
  <p className="tile-description">
    Clue: According to the Protecting Mental Health section of this page, mental
    health is a ______ right — and everyone deserves access to the care they
    need to reach their full potential.
  </p>
);

const GlobalGivingDescription = () => (
  <p className="tile-description">
    Discover the clue word by exploring{" "}
    <a
      href={"https://www.globalgiving.org/"}
      target="_blank"
      rel="noopener noreferrer"
    >
      Global Giving
    </a>{" "}
    and using the clue found at the top right of the card! Submit the clue in
    the "Claim Points" section on the Events page to earn points.
    <br />
    <br />
    Need help? Reach out to women@slalom.com.
  </p>
);

const GlobalGivingClue = () => (
  <p className="tile-description">
    Clue: GlobalGiving is a nonprofit that supports other nonprofits by
    connecting them to ______ and companies.
  </p>
);

// const CallingAllArtistsDescription = () => (
//   <p className="tile-description">
//     In celebration of Women<span>&apos;</span>s History Month, Slalom Women will
//     be curating and hosting a virtual gallery. We are currently seeking artworks
//     made by Slalomers. The brief is simple: Innovate Influence Inspire!
//     Submissions are open to all of Slalom until Monday, March 13. You could win
//     up to 300 points if your artwork is selected to be featured!
//     <br />
//   </p>
// );

interface OtherwaysToParticipateCardContent {
  title: string;
  points: number;
  descriptionComponent: () => React.ReactElement;
  clue: () => React.ReactElement;
  includeClue: boolean;
  button: { value: string; href: string };
}
const cardContent: OtherwaysToParticipateCardContent[] = [
  {
    title: "Discover the Clue word: AnitaB",
    points: 200,
    descriptionComponent: AnitaBDescription,
    clue: AnitaBClue,
    includeClue: true,
    button: { value: "Claim Points", href: CLAIM_POINTS_PAGE }
  },
  {
    title: "Discover the Clue word: Every Girl Shines",
    points: 200,
    descriptionComponent: EveryGirlShinesDescription,
    clue: EveryGirlShinesClue,
    includeClue: true,
    button: { value: "Claim Points", href: CLAIM_POINTS_PAGE }
  },
  {
    title: "Discover the Clue word: Jessie’s Centre",
    points: 200,
    descriptionComponent: JessieCentreDescription,
    clue: JessieCentreClue,
    includeClue: true,
    button: { value: "Claim Points", href: CLAIM_POINTS_PAGE }
  },
  {
    title: "Discover the Clue word: Project Hope",
    points: 200,
    descriptionComponent: ProjectHopeDescription,
    clue: ProjectHopeClue,
    includeClue: true,
    button: { value: "Claim Points", href: CLAIM_POINTS_PAGE }
  },
  {
    title: "Discover the Clue word: Global Giving",
    points: 200,
    descriptionComponent: GlobalGivingDescription,
    clue: GlobalGivingClue,
    includeClue: true,
    button: { value: "Claim Points", href: CLAIM_POINTS_PAGE }
  },
  {
    title: "Sign up for 1Password",
    points: 300,
    descriptionComponent: signUpForOnePasswordDescription,
    clue: signUpForOnePasswordClue,
    includeClue: false,
    button: { value: buttonLabels.ONE_PASSWORD, href: MAIL_TO_SECURITY }
  }
  // {
  //   title: "📣 Calling all artists!",
  //   points: 100,
  //   descriptionComponent: CallingAllArtistsDescription,
  //   clue: signUpForOnePasswordClue,
  //   includeClue: false,
  //   button: {
  //     value: "Submit Artwork",
  //     href: "https://forms.office.com/r/ECuCjRhTE9"
  //   }
  // }
];

const OtherWaysToParticipate = () => {
  return (
    <div className="other-ways-to-earn-page section-container">
      <div className="section-header">
        <div className="section-title">Other ways to earn points</div>
        <Tooltip>
          <p className="OtherWaysToEarnPointsToolTip">
            Points for these activities are added manually and will appear by
            the end of the month
          </p>
        </Tooltip>
      </div>
      <div className="other-ways-to-earn-container">
        {cardContent.map(
          (
            { title, points, descriptionComponent, clue, includeClue, button },
            idx
          ) => (
            <OtherWaysToParticipateCard
              key={`other-ways-to-earn-card-${idx}`}
              title={title}
              points={points}
              description={descriptionComponent()}
              clue={clue()}
              includeClue={includeClue}
              buttonValue={button.value}
              buttonHref={button.href}
            />
          )
        )}
      </div>
    </div>
  );
};

export default OtherWaysToParticipate;
