export const Roles = {
  solutionOwner: "Solution Owner",
  srSolutionOwner: "Senior Solution Owner",
  programSolutionOwner: "Program Solution Owner",
  frontEndEngineer: "Front End Engineer",
  smeArchitect: "SME Architect",
  smeDatabase: "SME Database",
  xr: "XR Engineer",
  sme: "SME",
  xd: "Experience Design",
  lvd: "Lead Visual Designer",
  fullStackEngineer: "Full Stack Engineer",
  softwareEngineer: "Software Engineer",
  qualityLead: "Lead Quality Engineer",
  qualityEngineer: "Quality Engineer",
  architect: "Architect",
  dataAnalytics: "Data & Analytics",
  devOps: "DevOps",
  producer: "Producer",
  leadership: {
    projectLead: "Project Lead",
    projectSupport: "Project Support",
    festivalCreator: "Festival Creator",
    projectAdvisor: "Project Advisor",
    marketingLead: "Marketing Lead",
    eventsPlatformLead: "Events Platform Lead",
    design: "Design Producer",
    global: "Global Program Lead",
    projectManager: "Project Manager",
    founder: "Founder",
    isg: "Information Security & Governance",
    programDirector: "Program Director",
    vp: "VP",
    dataPrivacy: "Data Privacy",
    legal: "Legal",
    communicationLead: "Communication Lead",
    contentLead: "Content Lead"
  }
};
