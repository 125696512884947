import React from "react";
import "./ViewRecording.scss";
import { EButtonStyle } from "../../utils/buttonUtils";

type Props = {
  style: string;
  type: "submit" | "button" | "reset";
  value: string;
  onClick: (event?: any) => void;
};

const ViewRecording: React.FC<Props> = ({
  style,
  type,
  value,
  onClick
}): React.ReactElement => {
  let buttonStyle = style || EButtonStyle.PRIMARY;
  const buttonClasses = [
    "app-viewrecording",
    `app-viewrecording-${buttonStyle}`
  ];

  return (
    <div className="app-viewrecording-generic-container">
      <button className={buttonClasses.join(" ")} type={type} onClick={onClick}>
        <span>{value}</span>
      </button>
    </div>
  );
};

export default ViewRecording;
