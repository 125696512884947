export const Titles = {
  principal: "Principal",
  consultant: "Consultant",
  seniorArchitect: "Senior Architect",
  salesExec: "Sales Executive",
  specialEvents: "Special Events & Ops Specialist",
  seniorDeliveryPrincipal: "Senior Delivery Principal",
  seniorManager: "Senior Manager",
  seniorConsultant: "Senior Consultant",
  seniorPrincipal: "Senior Principal",
  seniorDirector: "Senior Director",
  director: "Director",
  managingDirector: "Managing Director",
  seniorEngineer: "Senior Engineer",
  allianceManager: "Alliance Manager",
  analyst: "Analyst",
  programManager: "Program Manager",
  corporateCouncil: "Corporate Council",
  associateGenCouncil: "Associate General Counsel"
};
