import React from "react";
import "./SplashPage.scss";
import useWindowSize, {
  deviceWidthBreakpoints
} from "../../utils/useWindowSize";

import splashDesktop from "../../assets/videos/WHM_Pre-Launch Animation_desktop.mp4";
import splashTablet from "../../assets/videos/WHM_Pre-Launch Animation_mobile.mp4";
import splashMobile from "../../assets/videos/WHM_Pre-Launch Animation_mobile.mp4";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";

const { TABLET_MAX, MOBILE_MAX } = deviceWidthBreakpoints;

const renderResponsiveBackgroundMedia = (width: number) =>
  width > TABLET_MAX
    ? renderBackground(splashDesktop)
    : width > MOBILE_MAX
    ? renderBackground(splashTablet)
    : renderBackground(splashMobile);

const renderBackground = (vidSrc: any): JSX.Element => (
  <div>
    <video
      autoPlay
      loop
      muted
      preload="true"
      className="prelaunch-background-video"
      key={vidSrc}
    >
      <source src={vidSrc} type="video/mp4" />
    </video>
  </div>
);

const SplashPage = () => {
  const { width } = useWindowSize();

  return (
    <main className={`splash-container-${getResponsiveClassName(width)}`}>
      {renderResponsiveBackgroundMedia(width)}
    </main>
  );
};

export default SplashPage;
