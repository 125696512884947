import React from "react";
import "./FAQsPage.scss";
import useWindowSize, {
  deviceWidthBreakpoints
} from "../../utils/useWindowSize";
import FAQList from "../../components/FAQList/FAQList";
import {
  getResponsiveBackgroundClass,
  getMainContainerClass,
  getPageContainerClass
} from "../../utils/responsiveClassHelpers";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import ScrollToTop from "../../components/ScrollToTop";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";
import { Event } from "../../utils/consts/event";
import faqBannerDesktop from "../../assets/images/headers/faq/faq-header-text-desktop.png";
import faqBannerTablet from "../../assets/images/headers/faq/faq-header-text-tablet.png";
import faqBannerMobile from "../../assets/images/headers/faq/faq-header-text-mobile.png";

const { MOBILE_MAX, TABLET_MAX } = deviceWidthBreakpoints;

const FAQsPage = () => {
  const { width } = useWindowSize();

  const getFaqHeaderResponsiveClass = width =>
    `faq-header-${getResponsiveClassName(width)}`;

  const event = Event;
  const faqBanner =
    width > TABLET_MAX
      ? faqBannerDesktop
      : width > MOBILE_MAX
      ? faqBannerTablet
      : faqBannerMobile;

  return (
    <ScrollToTop>
      <PageWrapper bannerImage={faqBanner}>
        <div
          className={`hacktober-page faq-page ${getResponsiveBackgroundClass(
            width
          )}`}
        >
          <div className={getPageContainerClass(width)}>
            <div className={getMainContainerClass(width)}>
              <div className="section-container">
                <div className="section-title">Frequently asked questions</div>
                {event.faqSections.map((val, index) => (
                  <div key={index}>
                    <div
                      className={`faq-header ${getFaqHeaderResponsiveClass(
                        width
                      )}`}
                    >
                      <p>{val.name}</p>
                    </div>
                    <FAQList faqs={val.sectionData} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </ScrollToTop>
  );
};

export default FAQsPage;
