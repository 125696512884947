import React, { useEffect } from "react";
import "./ErrorPage.scss";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";

import useWindowSize, {
  deviceWidthBreakpoints
} from "../../utils/useWindowSize";
import { Link } from "react-router-dom";
import desktopVideo from "../../assets/videos/WHM_404 Animation_desktop.mp4";
import mobileVideo from "../../assets/videos/WHM_404 Animation_mobile.mp4";

interface ErrorPageProps {
  setShowNavBars: (setVal: boolean) => void;
}

const { TABLET_MAX, MOBILE_MAX } = deviceWidthBreakpoints;

const renderBackgroundMedia = (width: number) =>
  width > TABLET_MAX
    ? renderVideoBackground(width)
    : width > MOBILE_MAX
    ? renderTabletBackgroundImg(width)
    : renderMobileBackgroundImg(width);

const getBtnResponsiveClass = (width: number): string => {
  return `error-btn-container-${getResponsiveClassName(width)}`;
};
const getErrorPageContainerClass = (width: number): string => {
  return `error-page-container-${getResponsiveClassName(width)}`;
};
const mobileOrDesktop = (width: number): string => {
  return `error-page-${getResponsiveClassName(width)}`;
};

const renderTabletBackgroundImg = (width): JSX.Element => (
  <div>
    <video
      autoPlay
      loop
      muted
      preload="true"
      className="errorpage-background-video"
    >
      <source src={mobileVideo} type="video/mp4" />
    </video>
  </div>
);

const renderMobileBackgroundImg = (width): JSX.Element => (
  <div>
    <video
      autoPlay
      loop
      muted
      preload="true"
      className="errorpage-background-video"
    >
      <source src={mobileVideo} type="video/mp4" />
    </video>
  </div>
);

const renderVideoBackground = (width): JSX.Element => (
  <div>
    <video
      autoPlay
      loop
      muted
      preload="true"
      className="errorpage-background-video"
    >
      <source src={desktopVideo} type="video/mp4" />
    </video>
  </div>
);

const ErrorPage = ({ setShowNavBars }: ErrorPageProps) => {
  const { width } = useWindowSize();

  useEffect(() => {
    setShowNavBars(false);
  }, [setShowNavBars]);

  return (
    <>
      <div className="errorpage-video-overlay" />

      <div className={`hacktober-page ${mobileOrDesktop(width)}`}>
        {renderBackgroundMedia(width)}
        <div className={getErrorPageContainerClass(width)}>
          <div className={getBtnResponsiveClass(width)}>
            <Link to="/">
              <button
                className="app-button app-button-secondary"
                onClick={() => setShowNavBars(true)}
              >
                <div className="btn-label-primary">Return Home</div>
              </button>
            </Link>
            <a
              href="mailto: privacy@slalom.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="app-button app-button-secondary error-btn-text-only">
                <div className="btn-label">{`Contact Team >`}</div>
              </button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
