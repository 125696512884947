import React from "react";
import "./AboutPage.scss";
import AboutInfoList from "../../components/AboutInfoList/AboutInfoList";
import useWindowSize, {
  deviceWidthBreakpoints
} from "../../utils/useWindowSize";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import ScrollToTop from "../../components/ScrollToTop";
import Card from "../../components/Card/Card";
import { Event } from "../../utils/consts/event";
import Button from "../../components/Button/Button";
import { Link } from "react-router-dom";
import { EButtonStyle } from "../../utils/buttonUtils";
import AboutPartnersList from "../../components/AboutPartnersList/AboutPartnersList";
import aboutBannerDesktop from "../../assets/images/headers/about/about-header-text-desktop.png";
import aboutBannerTablet from "../../assets/images/headers/about/about-header-text-tablet.png";
import aboutBannerMobile from "../../assets/images/headers/about/about-header-text-mobile.png";

const { MOBILE_MAX, TABLET_MAX } = deviceWidthBreakpoints;

const getAboutHeaderResponsiveClass = width =>
  `about-header-${getResponsiveClassName(width)}`;

const getAboutWelcomeBlockResponsiveClass = width =>
  `about-welcome-${getResponsiveClassName(width)}`;

const getAboutWelcomeSectionResponsiveClass = width =>
  `welcome-section-${getResponsiveClassName(width)}`;

const getAboutContactSectionResponsiveClass = width =>
  `contact-section-container-${getResponsiveClassName(width)}`;

const event = Event;

const openInNewTab = url => {
  window.open(url, "_blank", "noreferrer");
};

const AboutPage = () => {
  const { width } = useWindowSize();
  const aboutBanner =
    width > TABLET_MAX
      ? aboutBannerDesktop
      : width > MOBILE_MAX
      ? aboutBannerTablet
      : aboutBannerMobile;
  const url =
    "https://sparkthink.slalom.com/survey/s/95e20eee-18a0-4052-a666-7f6cbe84803c";
  return (
    <ScrollToTop>
      <PageWrapper bannerImage={aboutBanner}>
        <div className="about-container">
          <section className={`${getAboutWelcomeBlockResponsiveClass(width)}`}>
            <section
              className={`welcome-section ${getAboutWelcomeSectionResponsiveClass(
                width
              )}`}
            >
              <div
                className={`about-header ${getAboutHeaderResponsiveClass(
                  width
                )}`}
              >
                {event.aboutPage.title}
              </div>
              <Card>
                <div className="about-page-text-body-container">
                  <div className="about-section-title">
                    Thank you for participating!
                  </div>
                  {event.aboutPage.eventIntroText}
                  <br />
                  <br />
                  <br />
                  <p>
                    {
                      " International Women's Day (IWD) globally celebrates the social, economic, cultural, and political achievements of women and marks a call to action for accelerating gender parity. IWD has been recognized for over a century, with the first celebration in 1911 being supported by over a million people across Austria, Denmark, Germany, and Switzerland. Before the formation of IWD, the Socialist Party of America, the United Kingdom's Suffragists and Suffragettes, and other groups campaigned for women's equality. Today, IWD is celebrated by many around the world."
                    }
                  </p>
                  <br />
                  <p>
                    {
                      " All month long—as women and allies—we’ll celebrate what makes us fiercely human. At Slalom, we’re working to create an equitable, inclusive, and fulfilling experience for our team members, as we know that’s how we bring our best to our customers and partners. It’s our collective responsibility to cultivate this type of environment, and we invite you to get involved with our Women’s History Month activities across Slalom in a way that’s meaningful to you. "
                    }
                  </p>
                  <br />
                  {event.aboutPage.eventIntroSecondaryText}
                </div>
              </Card>
            </section>
          </section>
          <section
            className={`welcome-section ${getAboutContactSectionResponsiveClass(
              width
            )} ${getAboutWelcomeSectionResponsiveClass(width)}`}
          >
            <Card
              containerClass={`contact-card-${getResponsiveClassName(width)}`}
            >
              <div className="about-page-text-body-container">
                <div className="contact-section-title">Got feedback?</div>
                {event.aboutPage.aboutEventsFeedbackText}
                <div className="flexible"></div>
                <div
                  className={`about-page-btn-container about-page-card-footer-${getResponsiveClassName(
                    width
                  )}`}
                >
                  <a
                    href="mailto: hacktober@slalom.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="app-button-primary"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </Card>
            <Card
              containerClass={`contact-card-${getResponsiveClassName(width)}`}
            >
              <div className="about-page-text-body-container">
                <div className="contact-section-title">
                  Join the Events Platform Build Team!
                </div>
                {event.aboutPage.aboutEventsJoinText}
                <div
                  className={`about-page-card-footer-${getResponsiveClassName(
                    width
                  )}`}
                >
                  <Link
                    to="/events"
                    target="_blank"
                    className="about-page-btn-container"
                  >
                    <Button
                      style={EButtonStyle.PRIMARY}
                      type="button"
                      value="Join Us"
                      onClick={() => openInNewTab(url)}
                    />
                  </Link>
                </div>
              </div>
            </Card>
            <Card
              containerClass={`contact-card-${getResponsiveClassName(width)}`}
            >
              <div className="about-page-text-body-container">
                <div className="contact-section-title">Caught a bug?</div>
                {event.aboutPage.aboutEventsBugText}
                <div className="flexible"></div>
                <div
                  className={`about-page-btn-container about-page-card-footer-${getResponsiveClassName(
                    width
                  )}`}
                >
                  <a
                    href="mailto: hacktober@slalom.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="app-button-primary"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </Card>
          </section>
          <AboutPartnersList />
          {Object.keys(event.aboutPage.aboutTeams).map((team, idx) => {
            return (
              <div key={idx}>
                {team === "Ownership" ? (
                  <div>
                    <div
                      className={`about-header ${getAboutHeaderResponsiveClass(
                        width
                      )}`}
                    >
                      Solution Ownership
                    </div>
                    <AboutInfoList
                      participants={event.aboutPage.aboutTeams[team]}
                    />
                  </div>
                ) : team === "Quality" ? (
                  <div>
                    <div
                      className={`about-header ${getAboutHeaderResponsiveClass(
                        width
                      )}`}
                    >
                      Quality Engineering
                    </div>
                    <AboutInfoList
                      participants={event.aboutPage.aboutTeams[team]}
                    />
                  </div>
                ) : (
                  <div>
                    <div
                      className={`about-header ${getAboutHeaderResponsiveClass(
                        width
                      )}`}
                    >
                      {team}
                    </div>
                    <AboutInfoList
                      participants={event.aboutPage.aboutTeams[team]}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </PageWrapper>
    </ScrollToTop>
  );
};

export default AboutPage;
