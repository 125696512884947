import React, { useState } from "react";
import "./PrelaunchPage.scss";
import useWindowSize, {
  deviceWidthBreakpoints
} from "../../utils/useWindowSize";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";
import Button from "../../components/Button/Button";
import LinkButton from "../../components/LinkButton/LinkButton";
// import { Link } from "react-router-dom";
import desktopVideo from "../../assets/videos/WHM_Pre-Launch Animation_desktop.mp4";
import mobileVideo from "../../assets/videos/WHM_Pre-Launch Animation_mobile.mp4";
import { EButtonStyle } from "../../utils/buttonUtils";
import Card from "../../components/Card/Card";

const { TABLET_MAX, MOBILE_MAX } = deviceWidthBreakpoints;

const renderBackgroundMedia = (width: number) =>
  width > TABLET_MAX
    ? renderVideoBackground(width)
    : width > MOBILE_MAX
    ? renderTabletBackgroundImg(width)
    : renderMobileBackgroundImg(width);

const renderTabletBackgroundImg = (width): JSX.Element => (
  // <img
  //   src={null}
  //   alt="Tablet Pre-Launch Page displaying a spaceship on a foreign planet with a tag reading 'SPACESHIP PRIVACY; DEEP SPACE RESEARCH SHIP, ON A MISSION TO PROTECT SLALOMITES DATA.'"
  //   className="prelaunch-background-image"
  // ></img>
  <div>
    <video
      autoPlay
      loop
      muted
      preload="true"
      className="prelaunch-background-video"
    >
      <source src={mobileVideo} type="video/mp4" />
    </video>
  </div>
);

const renderMobileBackgroundImg = (width): JSX.Element => (
  // <img
  //   src={null}
  //   alt="Mobile Pre-Launch Page displaying a spaceship on a foreign planet with a tag reading 'SPACESHIP PRIVACY; DEEP SPACE RESEARCH SHIP, ON A MISSION TO PROTECT SLALOMITES DATA.'"
  //   className="prelaunch-background-image"
  // ></img>
  <div>
    <video
      autoPlay
      loop
      muted
      preload="true"
      className="prelaunch-background-video"
    >
      <source src={mobileVideo} type="video/mp4" />
    </video>
  </div>
);

const renderVideoBackground = (width): JSX.Element => (
  <div>
    <video
      autoPlay
      loop
      muted
      preload="true"
      className="prelaunch-background-video"
    >
      <source src={desktopVideo} type="video/mp4" />
    </video>
  </div>
);

const PrelaunchPage = () => {
  const { width } = useWindowSize();
  const [viewCard, toggleViewCard] = useState(false);

  const handleClick = () => {
    toggleViewCard(!viewCard);
  };

  return (
    <>
      <div className="prelaunch-video-overlay" />
      <main
        className={`prelaunch-container prelaunch-container-${getResponsiveClassName(
          width
        )}`}
      >
        {renderBackgroundMedia(width)}
        {width > TABLET_MAX ? (
          <>
            <section className="prelaunch-topic-container-desktop">
              <div
                className={`button-container-${getResponsiveClassName(
                  width
                )} button-mt`}
              >
                <LinkButton
                  style={EButtonStyle.PRIMARY}
                  text="Open Spotify"
                  href={
                    "https://open.spotify.com/playlist/1fyzianCsfJIrlgvltTI8L?si=rPdt14A8TyahpP2tA16cmw"
                  }
                  newTab={true}
                />
              </div>
            </section>
            <section className="prelaunch-card-container-desktop">
              <Card>
                <div className="prelaunch-card-title">
                  <span role="img" aria-label="horn">
                    📣
                  </span>{" "}
                  Calling all artists!
                </div>
                <div className="prelaunch-card-body">
                  In celebration of Women’s History Month, Slalom Women will be
                  curating and hosting a virtual gallery. We are currently
                  seeking artworks made by Slalomers. The brief is simple:{" "}
                  <span className="bold">Innovate Influence Inspire!</span>{" "}
                  Submissions are open to all of Slalom until Monday, March 13.
                </div>
                <div className="prelaunch-card-button-container-desktop">
                  <LinkButton
                    style={EButtonStyle.PRIMARY}
                    text="Submit artwork"
                    href={"https://forms.office.com/r/ECuCjRhTE9"}
                    newTab={true}
                  />
                </div>
              </Card>
            </section>
          </>
        ) : (
          <>
            <section className="prelaunch-topic-container-desktop">
              <div
                className={`button-container-${getResponsiveClassName(
                  width
                )} button-mt`}
              >
                <div className="prelaunch-button-container-mobile">
                  <LinkButton
                    style={EButtonStyle.PRIMARY}
                    text="Open Spotify"
                    href={
                      "https://open.spotify.com/playlist/1fyzianCsfJIrlgvltTI8L?si=rPdt14A8TyahpP2tA16cmw"
                    }
                    newTab={true}
                  />
                </div>
                <div className="prelaunch-button-container-mobile">
                  <Button
                    onClick={() => handleClick()}
                    value="📣 Calling all artists!"
                  ></Button>
                </div>
              </div>
            </section>
            {viewCard && (
              <section className="prelaunch-card-container-mobile">
                <Card>
                  <div className="prelaunch-card-title">
                    <span role="img" aria-label="horn">
                      📣
                    </span>{" "}
                    Calling all artists!
                  </div>
                  <div className="prelaunch-card-body">
                    In celebration of Women’s History Month, Slalom Women will
                    be curating and hosting a virtual gallery. We are currently
                    seeking artworks made by Slalomers. The brief is simple:{" "}
                    <span className="bold">Innovate Influence Inspire!</span>{" "}
                    Submissions are open to all of Slalom until Monday, March
                    13.
                  </div>
                  <div className="prelaunch-card-button-container-mobile">
                    <LinkButton
                      style={EButtonStyle.PRIMARY}
                      text="Submit artwork"
                      href={"https://forms.office.com/r/ECuCjRhTE9"}
                      newTab={true}
                    />
                  </div>
                </Card>
              </section>
            )}
          </>
        )}
      </main>
    </>
  );
};

export default PrelaunchPage;
