import React from "react";
import Leaderboard from "../../components/Leaderboard/Leaderboard";
import "./WinnersPage.scss";
import {
  getResponsiveBackgroundClass,
  getMainContainerClass,
  getPageContainerClass
} from "../../utils/responsiveClassHelpers";
import useWindowSize, {
  deviceWidthBreakpoints
} from "../../utils/useWindowSize";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import ScrollToTop from "../../components/ScrollToTop";
import Card from "../../components/Card/Card";
import LinkButton from "../../components/LinkButton/LinkButton";
import PrizesList from "../../components/PrizesList/PrizesList";
import TopLeaderCard from "../../components/TopLeaderCard/TopLeaderCard";
import { IParticipant } from "../../models/participant.model";
import { ELeaderboardOption } from "../../components/Leaderboard/Leaderboard.util";
import { Event } from "../../utils/consts/event";
import homeBannerDesktop from "../../assets/images/headers/home/home-header-text-desktop.png";
import homeBannerTablet from "../../assets/images/headers/home/home-header-text-tablet.png";
import homeBannerMobile from "../../assets/images/headers/home/home-header-text-mobile.png";
import galleryQRDesktop from "../../assets/images/galleryQRDesktop.png";
import galleryQRTablet from "../../assets/images/galleryQRTablet.png";
import galleryQRMobile from "../../assets/images/galleryQRMobile.png";
import PageBanner from "../../components/PageBanner/PageBanner";
import { EButtonStyle } from "../../utils/buttonUtils";

const joinUsSurveyLink =
  "https://sparkthink.slalom.com/survey/s/95e20eee-18a0-4052-a666-7f6cbe84803c";

const prizesList: { title: string; text: string }[] = [
  {
    title: "Top Five Individuals",
    text:
      "The top five individuals, globally, will receive a Slalom gift card to buy Slalom swag!"
  },
  {
    title: "Top Three Markets",
    text:
      "A HUGE shout out to our top three winning markets! In an effort to contribute to the Protect and Restore phase referenced at our Global Town Hall, we will need to keep our prizes for the winning markets to bragging rights for 2023. We cannot wait to see you at the Slalom Women Summit in the fall, and don't forget to start planning for WHM 2024!"
  }
];

const { TABLET_MAX, MOBILE_MAX } = deviceWidthBreakpoints;

const groupedWinners: IParticipant[] = [
  {
    globalRank: "1",
    firstName: "Hyacinthia",
    lastName: "Stephen",
    market: "New York City",
    marketRank: "1"
  },
  {
    globalRank: "2",
    firstName: "Dristi",
    lastName: "Adhikari",
    market: "Denver",
    marketRank: "1"
  },
  {
    globalRank: "3",
    firstName: "Sharmistha",
    lastName: "Datta",
    market: "Detroit",
    marketRank: "1"
  },
  {
    globalRank: "4",
    firstName: "Lila",
    lastName: "Neupane",
    market: "Columbus",
    marketRank: "1"
  },
  {
    globalRank: "5",
    firstName: "Marjan",
    lastName: "Radfar",
    market: "Vancouver",
    marketRank: "1"
  }
];

const WinnersPage = () => {
  const { width } = useWindowSize();
  const bannerImg =
    width > TABLET_MAX
      ? homeBannerDesktop
      : width > MOBILE_MAX
      ? homeBannerTablet
      : homeBannerMobile;
  const galleryQR =
    width > TABLET_MAX
      ? galleryQRDesktop
      : width > MOBILE_MAX
      ? galleryQRTablet
      : galleryQRMobile;

  return (
    <ScrollToTop>
      <PageWrapper bannerImage={bannerImg}>
        <div
          className={`hacktober-page winners-page ${getResponsiveBackgroundClass(
            width
          )}`}
        >
          <div className={getPageContainerClass(width)}>
            <div className={getMainContainerClass(width)}>
              <div className="section-container">
                <Card>
                  <div className="gallery-tile-title">
                    Take a final look at the Virtual Gallery!
                  </div>
                  <div className="gallery-tile-content">
                    Enjoy a celebratory display of all the amazing art featured
                    this month!
                  </div>
                  <div>
                    <PageBanner bannerImage={galleryQR}></PageBanner>
                  </div>
                  <div className="tile-button-container">
                    <LinkButton
                      text="Visit the gallery in browser"
                      style={EButtonStyle.PRIMARY}
                      href="https://slozzie.staging.8thwall.app/whm-xr-gallery/"
                      newTab={true}
                    />
                  </div>
                </Card>
              </div>
              <div className="section-container" id="winners-note-section">
                <Card>
                  <div className="about-page-text-body-container">
                    <div className="about-section-title">
                      Thank you for participating!
                    </div>
                    <p>{Event.winnersPage.section1Description}</p>
                  </div>
                </Card>
              </div>
              <div
                className="section-container flex-container"
                id="winners-info-section"
              >
                <Card>
                  <React.Fragment>
                    <h2>{Event.winnersPage.feedbackTitle}</h2>
                    <p>{Event.winnersPage.feedbackText}</p>
                    <LinkButton
                      text="Contact Us"
                      href="mailto:hacktober@slalom.com"
                      newTab={true}
                    />
                  </React.Fragment>
                </Card>
                <Card>
                  <React.Fragment>
                    <h2>{Event.winnersPage.joinTitle}</h2>
                    <p>{Event.winnersPage.joinText}</p>
                    <LinkButton
                      text="Join Us"
                      href={joinUsSurveyLink}
                      newTab={true}
                    />
                  </React.Fragment>
                </Card>
                <Card>
                  <React.Fragment>
                    <h2>{Event.winnersPage.bugTitle}</h2>
                    <p>{Event.winnersPage.bugText}</p>
                    <LinkButton
                      text="Contact Us"
                      href="mailto:hacktober@slalom.com"
                      newTab={true}
                    />
                  </React.Fragment>
                </Card>
              </div>
              <Leaderboard isLocalLeaderBoard={false} />
              <PrizesList prizes={prizesList}></PrizesList>
              <div className="section-container" id="gameday-winners-section">
                <Card title="Global Individual Winners">
                  <div id="gameday-winners">
                    {groupedWinners.map((winner, idx) => (
                      <TopLeaderCard
                        data={winner}
                        key={`gameday-winner-${idx}`}
                        position={idx}
                        type={ELeaderboardOption.Global}
                      />
                    ))}
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </ScrollToTop>
  );
};

export default WinnersPage;
