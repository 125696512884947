import React from "react";
import { EButtonStyle } from "../../utils/buttonUtils";
import Card from "../Card/Card";
import LinkButton from "../LinkButton/LinkButton";
import PageBanner from "../PageBanner/PageBanner";
import galleryQRDesktop from "../../assets/images/galleryQRDesktop.png";
import galleryQRTablet from "../../assets/images/galleryQRTablet.png";
import galleryQRMobile from "../../assets/images/galleryQRMobile.png";
import useWindowSize, {
  deviceWidthBreakpoints
} from "../../utils/useWindowSize";
import "./VirtualGallery.scss";

interface VirtualGalleryProps {
  title: string;
  content: string;
}

const VirtualGallery = ({ title, content }: VirtualGalleryProps) => {
  const { width } = useWindowSize();
  const { MOBILE_MAX, TABLET_MAX } = deviceWidthBreakpoints;
  const galleryQR =
    width > TABLET_MAX
      ? galleryQRDesktop
      : width > MOBILE_MAX
      ? galleryQRTablet
      : galleryQRMobile;

  return (
    <div className="section-container">
      <Card>
        <div className="gallery-tile-title">{title}</div>
        <div className="gallery-tile-content">{content}</div>
        <div>
          <PageBanner bannerImage={galleryQR}></PageBanner>
        </div>
        <div className="tile-button-container">
          <LinkButton
            text="Visit the gallery in browser"
            style={EButtonStyle.PRIMARY}
            href="https://slozzie.staging.8thwall.app/whm-xr-gallery/"
            newTab={true}
          />
        </div>
      </Card>
    </div>
  );
};

export default VirtualGallery;
