import React from "react";
import { useQuery } from "react-query";
import CountUp from "react-countup";
import "./Rankings.scss";
import { getParticipant } from "../../ApiHelper";
import useWindowSize, {
  deviceWidthBreakpoints
} from "../../utils/useWindowSize";

const Rankings = () => {
  const { data: participantData } = useQuery(["participant"], getParticipant);
  const { width } = useWindowSize();
  const { TABLET_MAX } = deviceWidthBreakpoints;

  return (
    <>
      {width > TABLET_MAX && (
        <div className="rankings-container">
          <span className="local-ranking">
            <span>Local: #</span>
            {participantData?.marketRank ? (
              <CountUp
                end={parseInt(participantData?.marketRank)}
                duration={2}
              />
            ) : (
              ""
            )}
          </span>
          <span className="global-ranking">
            <span>Global: #</span>
            {participantData?.globalRank ? (
              <CountUp
                end={parseInt(participantData?.globalRank)}
                duration={2}
              />
            ) : (
              ""
            )}
          </span>
        </div>
      )}
    </>
  );
};

export default Rankings;
