import React, { useState } from "react";
import "./FAQTile.scss";
import upArrow from "../../assets/images/up-arrow.svg";
import downArrow from "../../assets/images/down-arrow.svg";
import Card from "../Card/Card";

type FAQType = {
  title: string;
  body: Array<{ itemHeader?: string; itemText: string }>;
};
interface FAQTileProps {
  faq: FAQType;
}

interface FaqTileImageProps {
  isCollapsed: boolean;
}

const BOOK_REGEX = /(ReadThistoGetSmarter)/;
const WORKDAY_TRAININGS_REGEX = /LGBTWhat-workday-training-link/;
const PRISM_SHAREPOINT_REGEX = /prism-sharepoint-site/;
const JOIN_EVENTS_TEAM_REGEX = /join-events-platform-sparkthink/;

const parseContentLinks = content => {
  return content.split(" ").map((part, index: number) =>
    WORKDAY_TRAININGS_REGEX.test(part) ? (
      <a
        href="https://www.myworkday.com/slalom/learning/path/86be59b3a73901015236a7d6a80b0000/course/89a48a2f0b800100e1aff221eb01dde2?type=9882927d138b100019b6a2df1a46018b"
        target="_blank"
        rel="noopener noreferrer"
        key={index}
      >
        Check out this list of trainings!
      </a>
    ) : BOOK_REGEX.test(part) ? (
      <em>Read This to Get Smarter</em>
    ) : PRISM_SHAREPOINT_REGEX.test(part) ? (
      <a
        href="https://twodegrees1.sharepoint.com/teams/IandD/SitePages/PRISM.aspx"
        target="_blank"
        rel="noopener noreferrer"
      >
        SharePoint page&nbsp;
      </a>
    ) : JOIN_EVENTS_TEAM_REGEX.test(part) ? (
      <a
        href="https://sparkthink.slalom.com/survey/s/63325a9d-ce4b-4da6-9538-86411c87a051"
        target="_blank"
        rel="noopener noreferrer"
      >
        Sign up here!
      </a>
    ) : part.endsWith("slalom.com") ? (
      <a href={`mailto:${part}`} target="_blank" rel="noopener noreferrer">
        {part}
      </a>
    ) : (
      part + " "
    )
  );
};

const FaqTileImage: React.FC<FaqTileImageProps> = ({
  isCollapsed
}): JSX.Element => {
  return (
    <div>
      <img
        src={isCollapsed ? downArrow : upArrow}
        alt="up-arrow-icon"
        className={`faq-tile-arrow-icon faq-tile-arrow-icon-${isUpOrDown(
          isCollapsed
        )}`}
      />
    </div>
  );
};

const renderCollapsedFAQTile = ({ title }: FAQType): JSX.Element => {
  return (
    <div className="faq-tile-title-container">
      <div className="faq-tile-title">{title}</div>
      <FaqTileImage isCollapsed={true} />
    </div>
  );
};

const renderExpandedFAQTile = ({ title, body }: FAQType): JSX.Element => {
  return (
    <React.Fragment>
      <div className="faq-tile-title-container">
        <div>{title}</div>
        <FaqTileImage isCollapsed={false} />
      </div>
      {body.map(item =>
        item.itemHeader ? (
          <div className="faq-tile-header-container" key={item.itemText}>
            <div style={{ fontWeight: "bold" }}>{item.itemHeader}</div>
            <div>{parseContentLinks(item.itemText)}</div>
          </div>
        ) : (
          <div className="faq-tile-body-container" key={item.itemText}>
            <div>{parseContentLinks(item.itemText)}</div>
          </div>
        )
      )}
    </React.Fragment>
  );
};

const renderCollapsedOrExpandedFAQTile = (faq, isCollapsed): JSX.Element => {
  return isCollapsed ? renderCollapsedFAQTile(faq) : renderExpandedFAQTile(faq);
};

const isUpOrDown = isCollapsed => {
  return isCollapsed ? "up" : "down";
};

const FAQTile = ({ faq }: FAQTileProps) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const handleIsCollapsed = () => {
    setIsCollapsed(!isCollapsed);
  };
  return (
    <div className="faq-tile-container" onClick={handleIsCollapsed}>
      <Card containerStyle={{ marginBottom: "16px" }}>
        {renderCollapsedOrExpandedFAQTile(faq, isCollapsed)}
      </Card>
    </div>
  );
};
export default FAQTile;
