import React from "react";
import { IMarket, IParticipant } from "../../models/participant.model";
import "./TopLeaderCard.scss";
import { ELeaderboardOption } from "../Leaderboard/Leaderboard.util";
// import { getDataImage } from "../../utils/imageUtils";
// import img from "../../assets/images/leaderboard-gold.png";

type LeaderCardProps = {
  type: ELeaderboardOption;
  data: IParticipant | IMarket;
  position: number;
};

const TopLeaderCard: React.FC<LeaderCardProps> = ({
  type,
  data,
  position
}): React.ReactElement => {
  const isUserParticipant =
    type === ELeaderboardOption.Global || type === ELeaderboardOption.Local;
  return (
    <div className={`top-leader-card top-leader-card--${position}`}>
      <div className="top-leader-content top-leader-shadow">
        {isUserParticipant ? (
          <>
            <h2>#{position + 1}</h2>
            {/* <img
              className="top-leader-avatar"
              src={getDataImage((data as IParticipant).image)}
              alt="avatar"
            /> */}
            <h2 className="top-leader-stat-h2">
              {(data as IParticipant).firstName}
            </h2>
            <h2 className="top-leader-stat-h2">
              {(data as IParticipant).lastName}
            </h2>
            <h3 className="top-leader-stat-h3">
              {(data as IParticipant).market}
            </h3>
            <h3 className="top-leader-stat-h4">
              {(data as IParticipant).totalPoints}
            </h3>
          </>
        ) : (
          <>
            <h3 className="top-leader-stat-h2">{(data as IMarket).name}</h3>
            <h3 className="top-leader-stat-h3">
              {(data as IMarket).totalPoints}
            </h3>
          </>
        )}
      </div>
      <div className={`position-image position-${position}`}></div>
    </div>
  );
};

export default TopLeaderCard;
