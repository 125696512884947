import React from "react";
import SecuryetiImg from "../../assets/images/securyeti.png";
import "./Securyeti.scss";

function Securyeti() {
  return (
    <div className="securyeti-container">
      <img src={SecuryetiImg} alt="" />
      <p>
        Congrats! You found Securyeti. Thanks for keeping Slalom safe! Email a
        screenshot to{" "}
        <a href="mailto: hacktober@slalom.com">hacktober@slalom.com</a> to claim
        your points.
      </p>
    </div>
  );
}

export default Securyeti;
