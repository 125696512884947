import React from "react";
import "./HomePage.scss";
import Leaderboard from "../../components/Leaderboard/Leaderboard";
import QuizOfTheDay from "../../components/QuizOfTheDay/QuizOfTheDay";
import OtherWaysToParticipate from "../../components/OtherWaysToParticipate";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import ScrollToTop from "../../components/ScrollToTop";
import Securyeti from "../../components/Securyeti/Securyeti";
import useWindowSize, {
  deviceWidthBreakpoints
} from "../../utils/useWindowSize";
import homeBannerDesktop from "../../assets/images/headers/home/home-header-text-desktop.png";
import homeBannerTablet from "../../assets/images/headers/home/home-header-text-tablet.png";
import homeBannerMobile from "../../assets/images/headers/home/home-header-text-mobile.png";
import VirtualGallery from "../../components/VirtualGallery/VirtualGallery";

const { MOBILE_MAX, TABLET_MAX } = deviceWidthBreakpoints;

function HomePage() {
  const { width } = useWindowSize();
  const homeBanner =
    width > TABLET_MAX
      ? homeBannerDesktop
      : width > MOBILE_MAX
      ? homeBannerTablet
      : homeBannerMobile;

  return (
    <ScrollToTop>
      <PageWrapper bannerImage={homeBanner}>
        <VirtualGallery
          title="The Virtual Gallery is Live!"
          content="Be sure to check back every week! The artwork will be refreshed every Monday"
        />
        <QuizOfTheDay />
        <Leaderboard />
        <Securyeti />
        <OtherWaysToParticipate />
      </PageWrapper>
    </ScrollToTop>
  );
}
export default HomePage;
