import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useQuery } from "react-query";
import { getEvents } from "../../ApiHelper";
import DropdownFilter from "../../components/Dropdown";
import EventCard from "../../components/EventCard";
import ClaimPoints from "../../components/ClaimPoints/ClaimPoints";
import LoadingSpinner from "../../components/LoadingSpinner";
import "./EventsPage.scss";
import { dayFilterOptions, timeFilterOptions, filteredEvents } from "./";
import {
  getResponsiveBackgroundClass,
  getMainContainerClass,
  getPageContainerClass
} from "../../utils/responsiveClassHelpers";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import ScrollToTop from "../../components/ScrollToTop";
import Tooltip from "../../components/Tooltip/Tooltip";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";
import ViewRecording from "../../components/ViewRecording/ViewRecording";
import useWindowSize, {
  deviceWidthBreakpoints
} from "../../utils/useWindowSize";
import eventsBannerDesktop from "../../assets/images/headers/events/events-header-text-desktop.png";
import eventsBannerTablet from "../../assets/images/headers/events/events-header-text-tablet.png";
import eventsBannerMobile from "../../assets/images/headers/events/events-header-text-mobile.png";

const { MOBILE_MAX, TABLET_MAX } = deviceWidthBreakpoints;
function EventsPage({ showClaimPoints }) {
  const { width } = useWindowSize();

  const eventsBanner =
    width > TABLET_MAX
      ? eventsBannerDesktop
      : width > MOBILE_MAX
      ? eventsBannerTablet
      : eventsBannerMobile;

  //const [selectedMarkets, setSelectedMarkets] = useState([]);
  const [selectedDays, setSelectedDays] = useState(["0"]);
  const [selectedTimes, setSelectedTimes] = useState(["all"]);
  const [speakerProfiles, setSpeakerProfiles] = useState([]);
  const {
    data: eventsData,
    error: eventsError,
    isLoading: eventsAreLoading
  } = useQuery(["events"], getEvents);
  const filterContainerClass = `filter-container-${getResponsiveClassName(
    width
  )}`;

  const filterBlockClass = `filter-block-${getResponsiveClassName(width)}`;

  return (
    <ScrollToTop>
      <PageWrapper bannerImage={eventsBanner}>
        <div
          className={`hacktober-page events-page ${getResponsiveBackgroundClass(
            width
          )}`}
        >
          <div className={getPageContainerClass(width)}>
            <div className={`${getMainContainerClass(width)}`}>
              {showClaimPoints ? (
                <ClaimPoints />
              ) : (
                <div className="claim-points-spacer"></div>
              )}
              <div className="events-container">
                <div className="events-tooltip">
                  <div className="section-title">Upcoming events</div>
                  <Tooltip>
                    <p>
                      Sign up for an event by adding it to your calendar below!
                      Make sure to open the downloaded file to Outlook.
                    </p>
                    <p>
                      Past events are available via the View Recordings button.
                    </p>
                  </Tooltip>
                </div>
                <div
                  className={
                    "app-card-display filter-container " + filterContainerClass
                  }
                >
                  <div>
                    <p>View previous events</p>
                    <ViewRecording
                      value="View Recordings"
                      onClick={() =>
                        window.open(
                          "https://twodegrees1.sharepoint.com/:f:/r/teams/SlalomWomen/Shared%20Documents/Womens%20History%20Month/Session%20Recordings?csf=1&web=1&e=yulp17",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div className={filterBlockClass}>
                    <p>Filter by day</p>
                    <DropdownFilter
                      placeholder="Select day"
                      onChange={(event, data) => setSelectedDays(data.value)}
                      color="#deff4d"
                      options={dayFilterOptions}
                      multiple
                      selection
                      clearable
                      className="events-filter"
                    />
                  </div>
                  <div>
                    <p>Filter by time</p>
                    <DropdownFilter
                      placeholder="Select time"
                      onChange={(event, data) => {
                        setSelectedTimes(data.value);
                      }}
                      options={timeFilterOptions}
                      multiple
                      selection
                      clearable
                      className="events-filter"
                    />
                  </div>
                </div>
                <div className="event-card-grid">
                  <LoadingSpinner
                    isLoading={eventsAreLoading}
                    error={eventsError}
                    size="massive"
                    color="#443A84"
                  >
                    {filteredEvents(
                      eventsData,
                      [],
                      selectedDays,
                      selectedTimes
                    ).map(event => {
                      return (
                        moment(event.startDateTime).isSameOrAfter(moment()) && (
                          <EventCard
                            key={event.eventId}
                            {...event}
                            speakerProfiles={speakerProfiles}
                            setSpeakerProfiles={setSpeakerProfiles}
                          />
                        )
                      );
                    })}
                  </LoadingSpinner>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </ScrollToTop>
  );
}

EventsPage.propTypes = {
  showClaimPoints: PropTypes.bool
};

export default EventsPage;
