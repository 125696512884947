import React, { FC, useState } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Amplify from "aws-amplify";
import { amplifyConfig } from "./config";
import AboutPage from "./pages/AboutPage";
import Footer from "./components/Footer";
import TopNavigationBar from "./components/TopNavigationBar";
import ErrorPage from "./pages/ErrorPage";
import SplashPage from "./pages/SplashPage";
import { refreshToken } from "./ApiHelper";
import "./App.scss";
import { handleUserAuth } from "./AuthHelper";
import {
  LOCAL_LOGIN_COMPLETE,
  LOCAL_USER_DATA
} from "./utils/consts/amplifyKeys";
import {
  eventHasEnded,
  isBeforeEventStart,
  showFullSite,
  showWinners
} from "./utils/eventUtil";
import EventsPage from "./pages/EventsPage";
import FAQsPage from "./pages/FAQsPage";
import HomePage from "./pages/HomePage";
import PrelaunchPage from "./pages/PrelaunchPage";
import WinnersPage from "./pages/WinnersPage/WinnersPage";
import WrapPage from "./pages/WrapPage";
// import WrapPage from "./pages/WrapPage/WrapPage";

Amplify.configure(amplifyConfig);

// const currentDomain = /:\/\/([^/]+)/.exec(window.location.href)[1];
const url = window.location.href;
const isProd = url.includes("whm.events.slalom.com");
const isDev = url.includes("dev.whm.events.hacktober.ninja");
const isLocal = url.includes("localhost:3000");

const showPrelaunch = false;
// const showSplash = false;

const App: FC = () => {
  const [showNavBars, setShowNavBars] = useState(true);
  const [isLoggedIn, setisLoggedIn] = useState(
    Boolean(localStorage.getItem(LOCAL_LOGIN_COMPLETE))
  );
  const [userData, setUserData] = useState(
    JSON.parse(String(localStorage.getItem(LOCAL_USER_DATA)))
  );

  refreshToken().then(() => {
    handleUserAuth(userData, setUserData, isLoggedIn, setisLoggedIn);
  });

  const HeaderFooterWrapper: React.FC<{ children: any }> = ({
    children
  }): React.ReactElement => (
    <React.Fragment>
      {children}
      <Footer />
    </React.Fragment>
  );

  const Prelaunch: React.FC = () => (
    <Switch>
      <Route exact path="/" render={() => <PrelaunchPage />} />
      <Route
        path="/events"
        render={() => (
          <HeaderFooterWrapper>
            <EventsPage showClaimPoints={false} />
          </HeaderFooterWrapper>
        )}
      />
      <Route
        path="/faqs"
        render={() => (
          <HeaderFooterWrapper>
            <FAQsPage />
          </HeaderFooterWrapper>
        )}
      />
      <Route render={() => <ErrorPage setShowNavBars={setShowNavBars} />} />
    </Switch>
  );

  const FullSite: React.FC = () => (
    <>
      {showNavBars && <TopNavigationBar />}
      <Switch>
        <Route exact path="/" render={() => <HomePage />} />
        <Route
          path="/events"
          render={() => <EventsPage showClaimPoints={true} />}
        />
        <Route path="/about" render={() => <AboutPage />} />
        <Route path="/faqs" render={() => <FAQsPage />} />
        <Route render={() => <ErrorPage setShowNavBars={setShowNavBars} />} />
      </Switch>
      {showNavBars && <Footer />}
    </>
  );

  const EndAnnouncements: React.FC = () => (
    <>
      <Switch>
        <Route exact path="/" render={() => <WrapPage />} />
        <Route render={() => <ErrorPage setShowNavBars={setShowNavBars} />} />
      </Switch>
      {showNavBars && <Footer />}
    </>
  );

  const Winners: React.FC = () => (
    <>
      {showNavBars && <TopNavigationBar />}
      <Switch>
        <Route exact path="/">
          <Redirect to="/winners" />
        </Route>
        <Route exact path="/winners" render={() => <WinnersPage />} />
        <Route path="/about" render={() => <AboutPage />} />
        <Route render={() => <ErrorPage setShowNavBars={setShowNavBars} />} />
      </Switch>
      {showNavBars && <Footer />}
    </>
  );

  return (
    <div className="App">
      {isLoggedIn ? (
        <BrowserRouter>
          {isLocal && showPrelaunch && <Prelaunch />}
          {isLocal && !showPrelaunch && !showWinners && <EndAnnouncements />}
          {/*
            Pre-launch:
            This usually has an introductory page and links to view the upcoming event calendar and FAQs.
            There is no home page because the Question of the Day / Leaderboard should only show
            during the event dates.
          */}
          {(isProd || isDev) && isBeforeEventStart && <Prelaunch />}
          {/*
            Main app:
            These are the main routes to render during the event.
          */}
          {(isProd || isDev) && showFullSite && <FullSite />}
          {/*
            Wrap:
            Similar to the pre-launch, except this is rendered after the event ends,
            and has a screen that summarizes the event, gives shout-outs to particular teams, etc.
          */}
          {(isProd || isDev) && eventHasEnded && !showWinners && (
            <EndAnnouncements />
          )}
          {(isProd || isDev || isLocal) && showWinners && <Winners />}
        </BrowserRouter>
      ) : (
        /*
          Splash:
          Renders while the SSO auth flow is triggering, so a blank screen is not
          shown to the user prior to being redirected to the MSFT login screen.
        */
        <Route render={() => <SplashPage />} />
      )}
    </div>
  );
};

export default App;
