import React from "react";
import { getTitleResponsiveClassName } from "../../utils/responsiveClassHelpers";
import useWindowSize from "../../utils/useWindowSize";
import Card from "../Card/Card";
import "./PrizesList.scss";

type PrizesListProps = {
  prizes: { title: string; text: string }[];
};

const PrizesList: React.FC<PrizesListProps> = ({ prizes }) => {
  const { width } = useWindowSize();
  return (
    <div className="prizes-list-container section-container">
      <div className="section-title">
        <h1 className={`${getTitleResponsiveClassName(width)}`}>Prizes</h1>
      </div>
      <div className="prizes-list">
        <div className="flex-container">
          {prizes &&
            prizes.map(({ text, title }, idx) => (
              <Card key={idx}>
                <h2 className="winners-card-title">{title}</h2>
                <p>{text}</p>
              </Card>
            ))}
        </div>
      </div>
    </div>
  );
};

export default PrizesList;
