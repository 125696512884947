import React, { FC } from "react";
import "./WrapPage.scss";
import useWindowSize, {
  deviceWidthBreakpoints
} from "../../utils/useWindowSize";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";
import { Event } from "../../utils/consts/event";
import Button from "../../components/Button/Button";
import { EButtonStyle } from "../../utils/buttonUtils";
import VirtualGallery from "../../components/VirtualGallery/VirtualGallery";
const event = Event;
const { MOBILE_MAX } = deviceWidthBreakpoints;

const VIEW_RECORDINGS_LINK = event.wrapPage.viewRecordingLink;
const FEEDBACK_LINK = event.wrapPage.feedbackEmailAddress;
const SHAREPOINT_LINK = event.wrapPage.sharePointLink;

const renderButtonContainer = (width: number): JSX.Element => (
  <div className={`wrapup-button-container-${getResponsiveClassName(width)}`}>
    <div className={width > MOBILE_MAX ? "top-buttons" : "top-buttons-tablet"}>
      <Button
        style={EButtonStyle.PRIMARY}
        type="button"
        value="View Recordings"
        onClick={() => window.open(VIEW_RECORDINGS_LINK, "_blank")}
      />
      <Button
        style={EButtonStyle.PRIMARY}
        type="button"
        value="Visit our SharePoint site"
        onClick={() => window.open(SHAREPOINT_LINK, "_blank")}
      />
      <Button
        style={EButtonStyle.PRIMARY}
        type="button"
        value="Give Feedback"
        onClick={() => window.open(FEEDBACK_LINK, "_blank")}
      />
    </div>
  </div>
);

const renderBackgroundImg = (): JSX.Element => <></>;

const wrapupPageContainerResponsive = (width: number): string => {
  return `wrapup-page-container-${getResponsiveClassName(width)}`;
};

const WrapPage: FC = () => {
  const { width } = useWindowSize();

  return (
    <>
      <div className="wrap-page-bg-container wrap-page">
        {renderBackgroundImg()}
        <div
          className={`wrap-page-container ${wrapupPageContainerResponsive(
            width
          )}`}
        >
          <div className={`wrapup-header-${getResponsiveClassName(width)}`}>
            {event.wrapPage.title}
          </div>
          <div className={`wrapup-content-${getResponsiveClassName(width)}`}>
            {event.wrapPage.message}
          </div>
          {renderButtonContainer(width)}
          <VirtualGallery
            title="Take a final look at the virtual gallery!"
            content="Enjoy a celebration display of all the amazing art featured this month"
          />
        </div>
      </div>
    </>
  );
};

export default WrapPage;
