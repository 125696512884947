import React, { useState, FC } from "react";
import { useQuery } from "react-query";
import LoadingSpinner from "../LoadingSpinner";
import { Link } from "react-router-dom";
import DropdownFilter from "../Dropdown";
import { getMarkets, getParticipants, getParticipant } from "../../ApiHelper";
import { ReactComponent as GlobalIcon } from "../../assets/images/network.svg";
import { ReactComponent as MarketIcon } from "../../assets/images/people.svg";
import { ReactComponent as LocalIcon } from "../../assets/images/person.svg";

import { sortedRankedPartcipants, otherLeaderParticipants } from ".";
import useWindowSize, {
  deviceWidthBreakpoints
} from "../../utils/useWindowSize";
import "./Leaderboard.scss";
import Tooltip from "../Tooltip/Tooltip";
import { ELeaderboardOption } from "./Leaderboard.util";
import TopLeaders from "../TopLeaders/TopLeaders";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";
import Card from "../Card/Card";
import { getDataImage } from "../../utils/imageUtils";

const { MOBILE_MAX, TABLET_MAX } = deviceWidthBreakpoints;

const leaderBoardContainerResponsiveClassName = width =>
  `leaderboard-container leaderboard-container-${getResponsiveClassName(
    width
  )}`;

const leaderListingContainerClassName = width =>
  `leader-listing-container${width > TABLET_MAX ? "" : "-mobile"}`;

const sectionTitleString = isLocalLeaderBoard =>
  isLocalLeaderBoard ? "Local Winners" : "Participant Leaderboard";

type LeaderProps = {
  rank: Number;
  image: any;
  firstName: string;
  lastName: string;
  name: string;
  market: string;
  totalPoints: Number;
  selectedOption: "Global" | "Market" | "Local";
  lastRow?: boolean;
};

const LeaderListing: FC<LeaderProps> = ({
  rank,
  image,
  firstName,
  lastName,
  name,
  market,
  totalPoints,
  selectedOption,
  lastRow = false
}: LeaderProps) => {
  return (
    <div
      className={`leader-listing ${
        lastRow ? "leader-listing:last-child" : ""
      } ${selectedOption === "Market" && "leader-listing-market"}`}
    >
      <div className="listing-rank listing-text-style">{rank}</div>
      <div className="listing-participant ">
        {selectedOption !== "Market" && (
          <img
            src={getDataImage(image)}
            className="listing-avatar"
            alt="avatar"
          />
        )}
        <div className="listing-text-style">{`${name ||
          `${firstName} ${lastName}`}`}</div>
      </div>
      <div>{market}</div>
      <div className="listing-text-score">{totalPoints}</div>
    </div>
  );
};

type LoadLeadersProps = {
  width: any;
  sortedParticipants: any;
  selectedOption: ELeaderboardOption;
  error: any;
  isLoading: boolean;
};

const LoadLeaderListing: FC<LoadLeadersProps> = ({
  width,
  sortedParticipants,
  selectedOption,
  error,
  isLoading
}: LoadLeadersProps) => {
  return (
    <div className={leaderListingContainerClassName(width)}>
      {sortedParticipants &&
        otherLeaderParticipants(sortedParticipants).map((participant, idx) => (
          <LoadingSpinner
            key={idx}
            isLoading={isLoading}
            error={error}
            color={"#00A4F9"}
            size={"small"}
          >
            <LeaderListing
              key={participant.user}
              {...participant}
              selectedOption={selectedOption}
              rank={idx + 4}
              lastRow={sortedParticipants.length === idx + 1}
            />
          </LoadingSpinner>
        ))}
    </div>
  );
};

type LeaderboardOrFallbackProps = {
  selectedOption: "Local" | "Global" | "Market" | string;
  isLocalLeaderBoard: Boolean;
  sortedParticipants: Array<any>;
  error: any;
  isLoading: boolean;
};

const LeaderboardOrFallback: FC<LeaderboardOrFallbackProps> = ({
  isLocalLeaderBoard,
  sortedParticipants,
  selectedOption,
  error,
  isLoading
}: LeaderboardOrFallbackProps) => {
  const { width } = useWindowSize();
  return sortedParticipants && sortedParticipants?.length > 0
    ? renderLeaderBoard(
        isLocalLeaderBoard,
        width,
        sortedParticipants,
        selectedOption,
        error,
        isLoading
      )
    : renderFallbackMessage();
};

const renderLeaderBoard = (
  isLocalLeaderBoard,
  width,
  sortedParticipants,
  selectedOption,
  error,
  isLoading
) => (
  <>
    <TopLeaders
      width={width}
      sortedParticipants={sortedParticipants}
      selectedOption={selectedOption}
      error={error}
      isLoading={isLoading}
    />
    {width > MOBILE_MAX && !isLocalLeaderBoard && (
      <LoadLeaderListing
        width={width}
        sortedParticipants={sortedParticipants}
        selectedOption={selectedOption}
        error={error}
        isLoading={isLoading}
      />
    )}
  </>
);

const renderFallbackMessage = () => (
  <div className="leaderboard-default-msg">
    Want to see your name on the leaderboard? Check out our other ways to earn
    points to get started!
  </div>
);

type LeaderboardOptionsProps = {
  selectedOption: string;
  setSelectedOption: (selectedOption: ELeaderboardOption) => void;
};

const LeaderboardOptions: FC<LeaderboardOptionsProps> = ({
  selectedOption,
  setSelectedOption
}: LeaderboardOptionsProps): JSX.Element => {
  const isSelectedStyleApplied = (option: string) =>
    option === selectedOption
      ? "selected-leaderboard-option"
      : "unselected-leaderboard-option";
  const leaderboardOptions = [
    { title: "Local", icon: <LocalIcon />, type: ELeaderboardOption.Local },
    { title: "Market", icon: <MarketIcon />, type: ELeaderboardOption.Market },
    { title: "Global", icon: <GlobalIcon />, type: ELeaderboardOption.Global }
  ];
  return (
    <div className="leaderboard-options">
      {leaderboardOptions.map(leaderboardOption => {
        const { title, icon, type } = leaderboardOption;
        return (
          <div
            key={title}
            className={`leaderboard-option ${isSelectedStyleApplied(title)}`}
            onClick={() => setSelectedOption(type)}
          >
            <span className="leaderboard-option-icon">{icon}</span>
            {title}
          </div>
        );
      })}
    </div>
  );
};

const renderMobileLeaderBoardOptions = (
  width: number,
  setSelectedOption: (selectedOption: ELeaderboardOption) => void
) => {
  const leaderboardOptions = [
    { text: "Global", value: ELeaderboardOption.Global },
    { text: "Market", value: ELeaderboardOption.Market },
    { text: "Local", value: ELeaderboardOption.Local }
  ];
  return width <= MOBILE_MAX ? (
    <div className="leaderboard-options-mobile">
      <DropdownFilter
        placeholder="Global"
        defaultValue="Global"
        onChange={(event, data) =>
          setSelectedOption(data.value as ELeaderboardOption)
        }
        options={leaderboardOptions}
        selection
        label=""
      />
    </div>
  ) : null;
};

type LeaderboardProps = {
  isLocalLeaderBoard: Boolean;
};

const Leaderboard: FC<LeaderboardProps> = ({
  isLocalLeaderBoard = false
}: LeaderboardProps) => {
  const { width } = useWindowSize();
  const [selectedOption, setSelectedOption] = useState<ELeaderboardOption>(
    ELeaderboardOption.Global
  );
  const {
    data: globalData,
    error: globalError,
    isLoading: globalIsLoading
  } = useQuery(["global", "Global"], getParticipants);
  const { data: participantData } = useQuery("participant", getParticipant);
  const {
    data: localData,
    error: localError,
    isLoading: localIsLoading
  } = useQuery(["local", participantData?.market], getParticipants);

  const {
    data: marketsData,
    error: marketsError,
    isLoading: marketsIsLoading
  } = useQuery(["markets"], getMarkets);

  let participants = null;
  let error = null;
  let isLoading = null;
  if (selectedOption === ELeaderboardOption.Global) {
    participants = globalData;
    error = globalError;
    isLoading = globalIsLoading;
  } else if (selectedOption === ELeaderboardOption.Market) {
    participants = marketsData;
    error = marketsError;
    isLoading = marketsIsLoading;
  } else if (selectedOption === ELeaderboardOption.Local) {
    participants = localData;
    error = localError;
    isLoading = localIsLoading;
  }

  const sortedParticipants =
    participants && sortedRankedPartcipants(participants);

  return (
    <div className="section-container">
      <div className="section-header">
        <div className="section-title">
          {sectionTitleString(isLocalLeaderBoard)}
        </div>

        <Tooltip>
          <p className="LeadershipboardToolTip">
            This leaderboard updates in real time. To learn more, check out the{" "}
            <Link to="/faqs">FAQ page</Link>.
          </p>
        </Tooltip>
      </div>

      <Card>
        {width > MOBILE_MAX ? (
          <LeaderboardOptions
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        ) : null}
        <div
          className={`${leaderBoardContainerResponsiveClassName(
            width
          )} ${selectedOption}-container`}
        >
          {renderMobileLeaderBoardOptions(width, setSelectedOption)}
          <LeaderboardOrFallback
            selectedOption={selectedOption}
            isLocalLeaderBoard={isLocalLeaderBoard}
            sortedParticipants={sortedParticipants}
            isLoading={isLoading}
            error={error}
          />
        </div>
      </Card>
    </div>
  );
};

export default Leaderboard;
