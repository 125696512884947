import React, { useState } from "react";
import { useQuery, useMutation, queryCache } from "react-query";
import { getQuiz } from "../../ApiHelper";
import { postQuizAnswer } from "../../ApiHelper";
import PropTypes from "prop-types";
import LoadingSpinner from "../LoadingSpinner";
import useWindowSize, {
  deviceWidthBreakpoints
} from "../../utils/useWindowSize";
import "./QuizOfTheDay.scss";
import DatabaseIconBlack from "../../assets/images/database-black.svg";
import Card from "../Card/Card";
import Button from "../Button/Button";
import { Quiz } from "../../models/quiz.model";

const { MOBILE_MAX, TABLET_MAX } = deviceWidthBreakpoints;

const QuizOfTheDay: any = ({ userData }) => {
  const { width } = useWindowSize();
  const viewport =
    width <= MOBILE_MAX ? "mobile" : width <= TABLET_MAX ? "tablet" : "desktop";

  const { data: quizData, error: quizError } = useQuery<Quiz>(
    ["quiz"],
    getQuiz
  );

  const [selectedAnswerId, setSelectedAnswerId] = useState(null);

  const [mutate, { status }] = useMutation(postQuizAnswer, {
    onSettled: () => {
      queryCache.invalidateQueries("quiz");
      queryCache.invalidateQueries("participant");
      queryCache.invalidateQueries("participants");
    }
  });

  const quizRequest = (quizId, answerId, user) => ({
    quizId: quizId,
    answerId: answerId,
    user: user
  });

  const handleClick = async () => {
    if (!selectedAnswerId) {
      return;
    }

    setSelectedAnswerId(null);
    const quizPOST = quizRequest(
      quizData?.quizId,
      selectedAnswerId,
      userData?.userId
    );
    mutate(quizPOST);
    return;
  };

  // determines radio button highlighting based on userAnswer
  const getQuizAnswerClass = currAnswerId => {
    const className = "quiz-button-";
    if (quizData?.userAnswer) {
      const { correctAnswerId, answerId, isCorrect } = quizData.userAnswer;
      if (correctAnswerId === currAnswerId) {
        return `${className}correct`;
      } else if (!isCorrect && currAnswerId === answerId) {
        return `${className}wrong`;
      }
    } else if (currAnswerId === selectedAnswerId) {
      return `${className}selected`;
    }
    return "";
  };

  const getAnswerExplanation = () => {
    if (quizData?.userAnswer) {
      const { explanation, isCorrect } = quizData.userAnswer;
      const text = isCorrect
        ? "Correct!"
        : "Sorry! You got this one wrong. Join us again tomorrow to answer a new question for a chance to earn points!";
      return (
        <p className={`${isCorrect ? "correct-" : "wrong-"}explanation`}>
          {`${text} ${explanation}`}
        </p>
      );
    }
  };

  return (
    <div className="section-container">
      <div className="section-title">Question of the day</div>
      <div className="quiz-of-the-day">
        <Card containerClass="quiz-description">
          <div className="points-container">
            <img src={DatabaseIconBlack} alt="" />
            <div className="label-highlighted">
              {quizData?.points || "?"} points
            </div>
          </div>
          <p>
            Showcase your Women&apos;s History knowledge by completing the
            Question of the Day! Each day, you will have the chance to answer a
            Women&apos;s History themed question to win points. The questions
            refresh every 24 hours, so don&apos;t miss out!
          </p>
        </Card>
        <Card containerClass="quiz-content">
          <div className="quiz-content__title">Today&apos;s Question</div>
          <div className="quiz-question">{quizData?.description}</div>
          <div className="quiz-answers-container">
            {getAnswerExplanation()}
            <LoadingSpinner
              isLoading={status === "loading"}
              error={quizError}
              size="massive"
              color="white"
            >
              <div className="quiz-answers-row">
                {quizData?.answers
                  ?.sort((a, b) => a.order - b.order)
                  .map(quizAnswer => {
                    return (
                      <button
                        key={quizAnswer.quizAnswerId}
                        className={[
                          "quiz-button",
                          getQuizAnswerClass(quizAnswer.quizAnswerId)
                        ]
                          .filter(Boolean)
                          .join(" ")}
                        disabled={!!quizData?.userAnswer}
                        onClick={() =>
                          setSelectedAnswerId(quizAnswer.quizAnswerId)
                        }
                      >
                        <div className="quiz-button-answer">
                          {quizAnswer.description}
                        </div>
                        <div className="quiz-button-circle">
                          <div className="quiz-button-circle--inner"></div>
                        </div>
                      </button>
                    );
                  })}
              </div>
            </LoadingSpinner>
          </div>
          <div className={`quiz-submission-container ${viewport}`}>
            <div className="quiz-submission-content">
              <Button
                disabled={!!quizData?.userAnswer || !selectedAnswerId}
                onClick={() => handleClick()}
                value="Submit"
              />
              {!quizData?.userAnswer && !selectedAnswerId && (
                <p>Complete section to continue</p>
              )}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

QuizOfTheDay.propTypes = {
  userData: PropTypes.object
};

export default QuizOfTheDay;
