import React from "react";
import "./TeamInfoTile.scss";
import { AboutInfoItem } from "../../utils/interfaces/AboutInfoItem.interface";

interface TeamInfoTileProps {
  participant: AboutInfoItem;
  allowFlipTile: boolean;
}
const TeamInfoTile = ({ participant }: TeamInfoTileProps) => {
  const renderProfileTile = ({
    fullName,
    role,
    market,
    pronouns
  }: AboutInfoItem): JSX.Element => {
    return (
      <div className="team-profile-front-tile-info-container">
        <div className="team-profile-name-container">{`${fullName}`}</div>
        <div className="team-profile-role-container">{`${role} | ${market}`}</div>
        <div className="team-profile-role-container">{`${pronouns}`}</div>
      </div>
    );
  };

  const { professionalPhoto } = participant;
  return (
    <div
      className="team-profile-tile-container profile-tile-margin"
      data-testid="team-profile-front-tile-container"
    >
      <div className="about-info-container">
        <div
          className="team-profile-front-tile-image-container"
          style={{
            backgroundImage: `url(${professionalPhoto})`
          }}
        />
        {renderProfileTile(participant)}
      </div>
    </div>
  );
};

export default TeamInfoTile;
