import partnerLogo from "../../assets/images/partners/microsoft.png";
import { Markets } from "./markets";
import { Roles } from "./roles";
import { Titles } from "./titles";
const DEFAULT_COVER = require("../../assets/images/about-profile-default.png");

export const Event = {
  eventName: "[EventName]",
  eventDescription: "[Some Event Details Here .........]",
  additionalDetails: "[Add Event Additional Details Here ........]",
  startDate: "Start Date",
  endDate: "End Date",
  nextYear: 2023,
  timeLineAndPrizes: [
    {
      title: "timeline-title-1",
      description: "timeline and prizes description - 1"
    },
    {
      title: "timeline-title-2",
      description: "timeline and prizes description - 2"
    },
    {
      title: "timeline-title-3",
      description: "timeline and prizes description - 3"
    },
    {
      title: "timeline-title-4",
      description: "timeline and prizes description - 4"
    }
  ],
  faqSections: [
    {
      name: "About Women's History Month",
      sectionData: [
        {
          title: "What is Women's History Month?",
          body: [
            {
              itemText:
                "Women’s History Month began as a national celebration in 1981 when Congress passed Pub. L. 97-28 which authorized and requested the President to proclaim the week beginning March 7, 1982 as “Women’s History Week.” Throughout the next five years, Congress continued to pass joint resolutions designating a week in March as “Women’s History Week.” In 1987 after being petitioned by the National Women’s History Project, Congress passed another law that designated the month of March 1987 as “Women’s History Month.” Between 1988 and 1994, Congress passed additional resolutions requesting and authorizing the President to proclaim March of each year as Women’s History Month. Since 1995, presidents have issued a series of annual proclamations designating the month of March as “Women’s History Month.” These proclamations celebrate the contributions women have made to the United States and recognize the specific achievements women have made over the course of American history in a variety of fields."
            }
          ]
        },
        {
          title: "What is this Platform?",
          body: [
            {
              itemText:
                "This platform may look familiar to you if you participated in events utilizing the Events Platform last year, or Hacktober.\nOur events platform blends cybersecurity with Slalom events to bring a fun, unique way to interact with our ERG and ID&E groups! "
            }
          ]
        },
        {
          title: "What is the Women's History Month Art Gallery?",
          body: [
            {
              itemText:
                "The WHM art gallery celebrates women in art whether they are artists who identify as women or art that represents women throughout history. The gallery houses curated images that are meaningful to each of us on the WHM team. We hope you enjoy these images and learn more about the fantastic women behind or in them."
            }
          ]
        },
        {
          title:
            "I need accommodation due to neurodiversity, visual & audio impairment, etc. What should I do?",
          body: [
            {
              itemText:
                "Please email hacktober@slalom.com  and include the accommodations you need to enable your participation."
            }
          ]
        }
      ]
    },
    {
      name: "Activities",
      sectionData: [
        {
          title: "What is a Question of the Day?",
          body: [
            {
              itemText:
                "The Question of the Day is a daily quiz during Women's History Month that, if answered correctly, will earn you points. The questions refresh every 24 hours, which is unique to this event, so be sure to check back every day for a chance to earn points!"
            }
          ]
        },
        {
          title: "Are all the events global and open to everyone?",
          body: [
            {
              itemText:
                "Yes -- all events are virtual and the time for each event is reflected in your time zone!"
            }
          ]
        },
        {
          title: "How do I sign up for an event?",
          body: [
            {
              itemText:
                "Review the events calendar on the Events page and select “Learn More” for the events you would like to attend. The button will take you to that event's SharePoint site. If you are having trouble, please email women@slalom.com  and the team can forward you the invite directly."
            }
          ]
        }
      ]
    },
    {
      name: "Site",
      sectionData: [
        {
          title: "Where can I go to find Event Recordings?",
          body: [
            {
              itemText:
                "All recordings will be posted via the “View Recordings” button on the Events page. Please note that event recordings are entered manually, and may take up to 4 business days to be uploaded to the playlist. "
            }
          ]
        },
        {
          title:
            "Can I invite clients or people external to Slalom to this event?",
          body: [
            {
              itemText:
                "No, please do not share any meetings or events externally! The Events Platform is exclusively built for Slalom employees."
            }
          ]
        },
        {
          title:
            "If I watch an event recording, can I still use the code to earn points?",
          body: [
            {
              itemText:
                "Yes -- event codes will only work until the end of Women's History Month, however, so please be sure to input your code by then."
            }
          ]
        }
      ]
    },
    {
      name: "Points & Prizes",
      sectionData: [
        {
          title: "How do you earn points for Women's History Month?",
          body: [
            {
              itemText:
                'There are many ways to earn points. You can earn points by answering the Question of the Day, attending events, and completing activities listed under "Other Ways to Earn Points."'
            }
          ]
        },
        {
          title:
            "Who do I contact if I think my point total is tallied incorrectly?",
          body: [
            {
              itemText:
                'If you feel that you have an incorrect point total, please reach out to hacktober@slalom.com  and our development team will investigate! Please note that the "Other ways to earn points" section has points added manually, so we appreciate your patience as those get added.'
            }
          ]
        },
        {
          title: "How do I claim points from the scavenger hunt?",
          body: [
            {
              itemText:
                "Enter your scavenger hunt answer in the claim points section on the events page! If you found the right item, you'll get your points."
            }
          ]
        },
        {
          title: "What prizes are available?",
          body: [
            {
              itemText:
                "At the end of the month, the Securyeti will tally all the points acquired and award prizes for the following categories:\n\nTop 5 winners - a Slalom Gear Gift Card! \n\nTop 3 Markets - Statuettes!"
            }
          ]
        },
        {
          title: "When will winners be announced?",
          body: [
            {
              itemText:
                "Winners will be announced by mid-April, and prizes will be distributed by May due to shipping delays. Please look out for an email if you've won! "
            }
          ]
        }
      ]
    },
    {
      name: "Contact",
      sectionData: [
        {
          title: "Who can I contact if I am having issues with the site?",
          body: [
            {
              itemText:
                "Please email hacktober@slalom.com  with screenshots and a thorough description of the issue, and someone from our team will work to troubleshoot."
            }
          ]
        },
        {
          title: "I have a question about an event. Who can I contact?",
          body: [
            {
              itemText: "Please reach out to women@slalom.com."
            }
          ]
        },
        {
          title: "Who can I contact regarding Women's History Month questions?",
          body: [
            {
              itemText: "Please reach out to women@slalom.com."
            }
          ]
        },
        {
          title:
            "Who developed this events platform, and how can I get involved?",
          body: [
            {
              itemText:
                "A wonderful team of volunteers brought this platform to life. You can check them out by visiting the About page. Interested in participating in the next events platform build? Sign up via the about page!"
            }
          ]
        },
        {
          title:
            "I’d love to use the Event Platform for the work I’m doing. Who can I reach out to?",
          body: [
            {
              itemText:
                "Please reach out to Reedhima Mandlik reedhima.mandlik@slalom.com."
            }
          ]
        }
      ]
    }
  ],
  partnersList: [
    {
      name: "Microsoft",
      link:
        "https://twodegrees1.sharepoint.com/teams/GlobalAlliances/SitePages/Microsoft.aspx",
      image: partnerLogo
    }
  ],
  aboutPage: {
    title: "Welcome to Slalom Women's History Month",
    eventIntroText:
      "Join the Slalom Women employee resource group (ERG) this March for Women’s History Month (WHM) and International Women’s Day (8 March 2023) as we focus on the ways we innovate, influence, and inspire every day.",
    eventIntroSecondaryText: `Have questions? Contact women@slalom.com for more information.`,
    aboutEventsFeedbackText: `We always welcome feedback on our platform or the events we are hosting! Feel free to contact us.`,
    aboutEventsJoinText: `Like what you see? Want experience building a custom platform in React? We welcome team members from every capability. Sign up via our link below!`,
    aboutEventsBugText: `Please report all bugs through our link below!`,
    aboutSponsors: [
      {
        name: "AnitaB",
        link: "https://anitab.org/",
        image: require("../../assets/images/partners/antab.png"),
        blurb:
          "AnitaB.org is a global nonprofit organization based in Belmont, California. Founded by computer scientists Anita Borg and Telle Whitney, the institute's primary aim is to recruit, retain, and advance women in technology."
      },
      {
        name: "Every Girl Shines",
        link: "https://everygirlshines.org/",
        image: require("../../assets/images/partners/every-girl-shines.png"),
        blurb:
          "Every Girl Shines is focused on developing middle and high school girls by pairing them with professional women who will lead them to creating a better world. By learning the values of loving, leading and living from strong female role models, stars of Every Girl Shines will feel empowered to shine bright like a diamond!"
      },
      {
        name: "Jessie's Centre",
        link: "https://jessiescentre.org/",
        image: require("../../assets/images/partners/jessies-centre.png"),
        blurb:
          "Jessie’s Centre was established to offer comprehensive services to pregnant teens and young mothers and their children facing enormous obstacles such as poverty and social isolation. Jessie’s Centre pursues a holistic and sustainable approach, recognizing the importance of economic and social conditions on health outcomes. Jessie’s offers a range of services to help empower the young women they serve."
      },
      {
        name: "Project Hope",
        link:
          "https://go.slalom.com/e/209772/2023-02-13/3hvs4c/913380355?h=7kSJIEkJ7FXIlPkCR9EW1A0J36_YLycy45kcqVb0-6Q",
        image: require("../../assets/images/partners/project-hope.png"),
        blurb:
          "Founded in 1958, Project HOPE is a renowned global health and humanitarian relief organization whose lifesaving work addresses the world’s greatest public health challenges, transforming lives and uplifting communities."
      },
      {
        name: "Global Giving",
        link:
          "https://go.slalom.com/e/209772/2023-02-13/3hvs4g/913380355?h=7kSJIEkJ7FXIlPkCR9EW1A0J36_YLycy45kcqVb0-6Q",
        image: require("../../assets/images/partners/global-giving.png"),
        blurb:
          "GlobalGiving connects nonprofits, donors, and companies in nearly every country in the world. They help fellow nonprofits access the funding, tools, training, and support they need to serve their communities. The mission is to transform aid and philanthropy to accelerate community-led change."
      }
    ],
    aboutTeams: {
      Leadership: [
        {
          fullName: "Reedhima Mandlik",
          role: `${Roles.leadership.programDirector}/${Roles.leadership.founder}`,
          market: Markets.chicago,
          professionalPhoto: require("../../assets/images/team/reedhima-mandlik.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "She/Her"
        },
        {
          fullName: "Kimberly James-Williams",
          role: `${Roles.leadership.design}`,
          market: Markets.charlotte,
          professionalPhoto: require("../../assets/images/team/kimberly-james-williams.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "She/Her"
        },
        {
          fullName: "Stephanie Andersen",
          role: `${Roles.leadership.global}`,
          market: Markets.atlanta,
          professionalPhoto: require("../../assets/images/team/stephanie-andersen.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "She/Her"
        },
        {
          fullName: "Fereshteh Amiri",
          role: `${Titles.programManager}`,
          market: Markets.toronto,
          professionalPhoto: require("../../assets/images/team/fereshteh-amiri.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "She/Her"
        },
        {
          fullName: "Nicole Brodie-Smith",
          role: `${Roles.leadership.eventsPlatformLead}`,
          market: Markets.vancouver,
          professionalPhoto: require("../../assets/images/team/nicole-brodie-smith.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "She/Her"
        },
        {
          fullName: "Lainie Ashton",
          role: `${Roles.leadership.communicationLead}`,
          market: Markets.seattle,
          professionalPhoto: require("../../assets/images/team/lainie-ashton.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "She/Her"
        },
        {
          fullName: "Liz Beauregard",
          role: `${Roles.leadership.contentLead}`,
          market: Markets.seattle,
          professionalPhoto: require("../../assets/images/team/liz-beauregard.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "She/Her"
        },
        {
          fullName: "Morgan Glisan",
          role: `${Roles.leadership.contentLead}`,
          market: Markets.houston,
          professionalPhoto: require("../../assets/images/team/morgan-glisan.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "She/Her"
        },
        {
          fullName: "Sydney Tawfik",
          role: `${Roles.leadership.contentLead}`,
          market: Markets.seattle,
          professionalPhoto: require("../../assets/images/team/sydney-tawfik.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "She/Her"
        }
      ],
      Ownership: [
        {
          fullName: "Bryan Hanks",
          role: Roles.programSolutionOwner,
          market: Markets.houston,
          professionalPhoto: require("../../assets/images/team/bryan-hanks.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him"
        },
        {
          fullName: "Isabella Antonini",
          role: Roles.srSolutionOwner,
          market: Markets.chicago,
          professionalPhoto: require("../../assets/images/team/isabella-antonini.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "She/Her"
        },
        {
          fullName: "Neel Bardhan",
          role: Roles.solutionOwner,
          market: Markets.chicago,
          professionalPhoto: require("../../assets/images/team/neel-bardhan.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him"
        }
      ],
      Database: [
        {
          fullName: "Erasmo Velez Baerga",
          role: Roles.smeDatabase,
          market: Markets.chicago,
          professionalPhoto: require("../../assets/images/team/erasmo-baerga.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him"
        }
      ],
      Architecture: [
        {
          fullName: "Kevin Orlando",
          role: Roles.smeArchitect,
          market: Markets.charlotte,
          professionalPhoto: require("../../assets/images/team/kevin-orlando.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him"
        }
      ],
      Design: [
        {
          fullName: "Karen Wittekind",
          role: Roles.lvd,
          market: Markets.chicago,
          professionalPhoto: require("../../assets/images/team/karen-wittekind.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "She/Her"
        },
        {
          fullName: "Roberta Lanzino",
          role: Roles.xd,
          market: Markets.chicago,
          professionalPhoto: require("../../assets/images/team/roberta-lanzino.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "She/Her"
        },
        {
          fullName: "Lily Li",
          role: Roles.xd,
          market: Markets.atlanta,
          professionalPhoto: require("../../assets/images/team/lily-li.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "She/Her"
        }
      ],
      Quality: [
        {
          fullName: "Liliana Allen",
          role: Roles.qualityLead,
          market: Markets.chicago,
          professionalPhoto: require("../../assets/images/team/liliana-allen.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "She/Her"
        }
      ],
      Development: [
        {
          fullName: "Alex Aung",
          role: Roles.softwareEngineer,
          market: Markets.cloudResidency,
          professionalPhoto: require("../../assets/images/team/alex-aung.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him"
        },
        {
          fullName: "Dhruv Patel",
          role: Roles.softwareEngineer,
          market: Markets.cloudResidency,
          professionalPhoto: require("../../assets/images/team/dhruv-patel.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him"
        },
        {
          fullName: "Jonathan Olmon",
          role: Roles.softwareEngineer,
          market: Markets.cloudResidency,
          professionalPhoto: require("../../assets/images/team/jonathan-olmon.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him"
        },
        {
          fullName: "Tony Fruzza",
          role: Roles.softwareEngineer,
          market: Markets.oc,
          professionalPhoto: require("../../assets/images/team/tony-fruzza.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him"
        },
        {
          fullName: "Eugene Yew",
          role: Roles.xr,
          market: Markets.sydney,
          professionalPhoto: require("../../assets/images/team/eugene-yew.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him"
        },
        {
          fullName: "Jared Cortez",
          role: Roles.softwareEngineer,
          market: Markets.sydney,
          professionalPhoto: require("../../assets/images/team/jared-cortez.jpg"),
          secondaryPhoto: DEFAULT_COVER,
          pronouns: "He/Him"
        }
      ]
    }
  },
  winnersPage: {
    title: "Welcome to Women's History Month",
    section1Title: "Thank you for participating!",
    section1Description:
      "As Women's History Month comes to an end, we would like to take a moment to express our sincerest appreciation for your incredible engagement and continued support throughout this meaningful series of events. Throughout the month, we have been privileged to share inspiring stories, educate and empower others, and celebrate the achievements of women who have made a significant impact. We could not have done this without your enthusiastic participation, thoughtful contributions and insightful comments. Your dedication and support have been truly inspiring, and we are grateful to have had you with us. Together, we have created a meaningful platform to recognize and celebrate the vital role that women have played in shaping history and in shaping our future. As Women's History Month ends, we look forward to continuing this important work while innovating, influencing, and inspiring one another. Thank you for being a part of our Women's History Month celebration and for making it such a remarkable success.",
    feedbackTitle: "Got Feedback?",
    feedbackText: `We always welcome feedback on our platform or the events we are hosting! Feel free to contact us.`,
    joinTitle: "Join the Events Platform Build Team!",
    joinText: `Like what you see? Want experience building a custom platform in React? We welcome team members from every capability. Sign up via our link below!`,
    bugTitle: "Caught a bug?",
    bugText: `Please report all bugs through our link below!`
  },
  wrapPage: {
    title: "Thank you for participating in Women's History Month!",
    message:
      "As Women's History Month comes to an end, we would like to take a moment to express our sincerest appreciation for your incredible engagement and continued support throughout this meaningful series of events.Throughout the month, we have been privileged to share inspiring stories, educate and empower others, and celebrate the achievements of women who have made a significant impact. We could not have done this without your enthusiastic participation, thoughtful contributions and insightful comments. Your dedication and support have been truly inspiring, and we are grateful to have had you with us. Together, we have created a meaningful platform to recognize and celebrate the vital role that women have played in shaping history and in shaping our future. As Women's History Month ends, we look forward to continuing this important work while innovating, influencing, and inspiring one another. Thank you for being a part of our Women's History Month celebration and for making it such a remarkable success.",
    sharePointLink:
      "https://twodegrees1.sharepoint.com/teams/IandD/SitePages/Slalom-Women.aspx",
    viewRecordingLink:
      "https://twodegrees1.sharepoint.com/:f:/r/teams/SlalomWomen/Shared%20Documents/Womens%20History%20Month/Session%20Recordings?csf=1&web=1&e=yulp17",
    feedbackEmailAddress:
      "https://sparkthink.slalom.com/login?referrer=https://sparkthink.slalom.com/vote/63d2c57e7a996c0001118215&auth=slalom&accessCode=357bd40a-f343-4d96-82a3-129df7bcdf56&collectorId=63d2c5f0b5824600014200fa",
    signUpLink:
      "https://sparkthink.slalom.com/survey/s/95e20eee-18a0-4052-a666-7f6cbe84803c"
  },
  preLaunchPage: {
    message: `Event specific pre-launch message here: Lorem ipsum dolor sit amet,
    consectetur adipiscing elit. Adipiscing auctor rhoncus, massa
    condimentum nibh amet, pulvinar.`,
    spotifyPlayList: "",
    videoLink: ""
  }
};
