import React from "react";

export default function useDetectOutsideClick(
  ref: React.MutableRefObject<any>,
  onOutsideClick: () => void
): void {
  React.useEffect(() => {
    function handleOutsideClick(event): void {
      if (
        !ref ||
        !ref.current ||
        ref.current.contains(event.target) ||
        !onOutsideClick
      ) {
        return;
      }

      onOutsideClick();
    }
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [ref, onOutsideClick]);
}
