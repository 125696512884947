import React from "react";
import useWindowSize from "../../utils/useWindowSize";
import "./PageWrapper.scss";
import PropTypes from "prop-types";
import ScrollToTopButton from "../ScrollToTopButton/ScrollToTopButton";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";
import PageBanner from "../PageBanner/PageBanner";

const PageWrapper = ({ bannerImage, children }) => {
  const { width } = useWindowSize();

  return (
    <div className="page-wrapper">
      <div
        className={`page-wrapper-content page-wrapper-content-${getResponsiveClassName(
          width
        )}`}
      >
        <div className={`page-bg-container ${getResponsiveClassName(width)}`} />
        <PageBanner bannerImage={bannerImage} />
        {children}
        <ScrollToTopButton />
      </div>
    </div>
  );
};

PageWrapper.propTypes = {
  children: PropTypes.any,
  bannerImage: PropTypes.node
};

export default PageWrapper;
